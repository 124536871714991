import {formatDate, Location} from '@angular/common';
import {AfterViewInit, Component, Directive, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as L from 'leaflet';
import {Observable} from 'rxjs';
import {debounceTime, finalize, switchMap, tap, map, startWith} from 'rxjs/operators';
import {Auswahl} from './_models/auswahl';
import { Finder } from './_models/finder';
import {Gefunden} from './_models/gefunden';
import { DbService } from './_services/db.service';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {Auswahl2} from './_models/auswahl2';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // tslint:disable-next-line:object-literal-sort-keys
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public filteredFluesse: Auswahl[];
  public filteredFische: Auswahl2[];
  public filteredCode: Auswahl[];
  fluesseControl = new FormControl();
  fischeControl = new FormControl();
  senderControl = new FormControl();
  public isLoading = false;
  public loginForm: FormGroup;


  public senders: Auswahl2[];
  public sender: Auswahl2;
  public fischtyp: Auswahl2;
  public fluss: Auswahl;
  public fisch: Auswahl2;

  public gefunden: Gefunden;
  public finder: Finder;
  public map;
  public layers;
  public pdf: number;
  public fishIcon;
  public isMobileResolution = false;
  public info = '';

  constructor(
    private service: DbService,
    private screenOrientation: ScreenOrientation) { }

  // tslint:disable-next-line:typedef
  public ngOnInit() {

    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
      // document.getElementById('map-tab').style.width = '100%';
      // document.getElementById('cord-tab').style.width = '0%';
      // document.getElementById('bgimg').style.width = '90%';
      // document.getElementById('bgimg').style.height = '100%';
      // document.getElementById('bgimg').style.left = '0%';
      this.changeOrientation();
      this.screenOrientation.onChange().subscribe(
        () => {
          this.changeOrientation();
        }
      );
    } else {
      this.isMobileResolution = false;
    }

    this.pdf = 0;
    // this.fischtyp = this.fluss = this.fisch = {id: 0, name: ''};
    this.service.getAll('senders')
    .subscribe(senders => this.senders = senders);
    // tslint:disable-next-line:radix
    this.gefunden =  { id: 0, fid: 0, sid: +window.location.search.split('=')[1], flid: 0, fischid: 0, findid: 0,
      datum:  formatDate(new Date(), 'yyyy_MM_dd', 'en'), laenge: 0,
      gewicht: 0, xcord: 0, ycord: 0, notiz: '', fort: ''};
    console.log('Sender ID is: ' + this.gefunden.sid);
    this.finder = { id: 0, name: '', vorname: '', strasse: '', plz: '', ort: '', telefon: '', email: '', iban: '', bank: '', bic: '' };

    this.fishIcon = L.icon({
      iconUrl: 'FishTrek.png',
      shadowUrl: 'schatten.png',

      iconSize:     [50, 50], // size of the icon
      shadowSize:   [2, 2], // size of the shadow
      iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    if (new URLSearchParams(window.location.search).get('sender')){
      // tslint:disable-next-line:radix
      this.service.getConcrete('sender', parseInt(new URLSearchParams(window.location.search).get('sender')))
        .subscribe(sender => {
          this.sender = sender;
          this.gefunden.sid = sender.id;
          console.log('Sender ID is: ' + this.gefunden.sid);
        });
    }else{
      this.sender = {id: 0, name: '', nameeng: '' };
    }

    this.map = L.map('map', {
      center: [ 51, 12 ],
      zoom: 5,
      tap: false,
    });


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 5,
      maxZoom: 19,
      // tslint:disable-next-line:object-literal-sort-keys
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);

    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      showMarker: false, // optional: true|false  - default true
      showPopup: false,
    });

    this.map.addControl(searchControl);

    this.layers = L.layerGroup();
    this.map.addLayer(this.layers);


    this.map.on('click', <LeafletMouseEvent>(e) => {
      this.gefunden.xcord = e.latlng.lat;
      this.gefunden.ycord = e.latlng.lng;
      this.layers.clearLayers();
      this.layers.addLayer(L.marker([e.latlng.lat, e.latlng.lng], {icon: this.fishIcon}));
    });

    /*
        this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          // tslint:disable-next-line:object-literal-sort-keys
          password: ['', Validators.required],
        });
    */



    this.fluesseControl.valueChanges
      .pipe(
      debounceTime(500),
      tap(() => {
        this.filteredFluesse = [];
        this.isLoading = true;
      }),
      switchMap(value => this.obsFluesse(value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
      )
    )
      .subscribe(
        data => this.filteredFluesse = data
        );

    this.fischeControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredFische = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsFische(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredFische = data
      );

    this.senderControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCode = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsCode(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(
        data => this.filteredCode = data
      );
  }
  ngAfterViewInit(): void {
  }
  obsFluesse(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilter('fluss', 'name', value);
  }
  obsFische(value: string): Observable<Auswahl2[]> {
      return this.service.getAllFilter('fisch', 'nameeng', value);
  }
  obsCode(value: string): Observable<Auswahl[]> {
    return this.service.getAllFilterAnd('code', 'code', value, this.gefunden.sid, this.gefunden.fid);
    }
  // tslint:disable-next-line:typedef
  displayFn(fisch: Auswahl2) {
    if (fisch) {
      return fisch.nameeng; }
  }

  changeFluss(): void {
    this.gefunden.flid = this.fluss.id;
  }

  changeFisch(): void {
    this.gefunden.fid = this.fischtyp.id;
  }

  changeCode(): void {
    this.gefunden.fischid = this.fisch.id;
  }

  changeOrientation(): void {
    if (this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY) {
      document.getElementById('map-frame').style.height = '14%';
      document.getElementById('map-frame').style.width = '75%';
      document.getElementById('map-frame').style.left = '10%';
    } else {
      document.getElementById('map-frame').style.height = '14%';
      document.getElementById('map-frame').style.width = '80%';
      document.getElementById('map-frame').style.left = '10%';


    }
  }

  // tslint:disable-next-line:typedef
  // get f() { return this.loginForm.controls; }
     save(): void {
      this.service.insertWith('finder', this.finder, this.gefunden)
        .subscribe(data => {
          this.pdf = data;
          this.info = 'Daten übertragen!';
          setTimeout( () => { this.info = ''; }, 5000 );
        });
    }

  reset(): void {
    this.fluss = {id: 0, name: ''};
    this.fisch = this.fischtyp = {id: 0, name: '', nameeng: ''};
    this.gefunden =  { id: 0, fid: 0,  sid: +window.location.search.split('=')[1], flid: 0, fischid: 0, findid: 0,
      datum:  formatDate(new Date(), 'yyyy_MM_dd', 'en'), laenge: 0,
      gewicht: 0, xcord: 0, ycord: 0, notiz: '', fort: ''};
    this.finder = { id: 0, name: '', vorname: '', strasse: '', plz: '', ort: '', telefon: '', email: '', iban: '', bank: '', bic: '' };
    this.layers.clearLayers();
    this.pdf = 0;
  }

}
